"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _redux = require("redux");

var _reactRedux = require("react-redux");

var _Component = _interopRequireDefault(require("./Component"));

var _commands = require("../ducks/commands");

var mapActionsToProps = function mapActionsToProps(dispatch) {
  return {
    closeDialog: (0, _redux.bindActionCreators)(_commands.closeDialog, dispatch)
  };
};

var _default = (0, _reactRedux.connect)(null, mapActionsToProps)(_Component["default"]);

exports["default"] = _default;
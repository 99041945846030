"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Notifications", {
  enumerable: true,
  get: function get() {
    return _components["default"];
  }
});
Object.defineProperty(exports, "notificationReducer", {
  enumerable: true,
  get: function get() {
    return _slice.reducer;
  }
});
Object.defineProperty(exports, "cleanNotifications", {
  enumerable: true,
  get: function get() {
    return _slice.cleanNotifications;
  }
});
Object.defineProperty(exports, "pushNotification", {
  enumerable: true,
  get: function get() {
    return _slice.pushNotification;
  }
});
Object.defineProperty(exports, "closeNotification", {
  enumerable: true,
  get: function get() {
    return _slice.closeNotification;
  }
});

var _components = _interopRequireDefault(require("./components"));

var _slice = require("./ducks/slice");
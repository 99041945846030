"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxForm = require("redux-form");

var _Component = _interopRequireDefault(require("./Component"));

var validate = function validate(values) {
  var errors = {};

  if (!values['name']) {
    errors['name'] = 'Обязательное поле';
  }

  if (!values['email']) {
    errors['email'] = 'Обязательное поле';
  } else {
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values['email'])) {
      errors['email'] = 'Неверный формат email';
    }
  }

  if (!values['phone']) {
    errors['phone'] = 'Обязательное поле';
  } else if (!/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(values['phone'])) {
    errors['phone'] = 'Неверное значение';
  }

  if (!values['password']) {
    errors['password'] = 'Обязательное поле';
  } else if (values['password'].length < 8) {
    errors['password'] = 'Пароль должен быть минимум 8 символов';
  } else if (!/[~`!@#$%^&*()\-\[\]_+={}'"|;:?/>.<,]+/.test(values['password'])) {
    errors['password'] = 'Пароль должен содержать спецсимволы';
  } else if (!/[0-9]+/.test(values['password'])) {
    errors['password'] = 'Пароль должен содержать цифры';
  } else if (!/[A-Z]+/.test(values['password'])) {
    errors['password'] = 'Пароль должен содержать заглавные буквы латинского алфавита';
  } else if (!/[a-z]+/.test(values['password'])) {
    errors['password'] = 'Пароль должен содержать строчные буквы латинского алфавита';
  } else if (/[а-яА-ЯЁё]/.test(values['password'])) {
    errors['password'] = 'Пароль не должен содержать буквы кириллицы';
  }

  if (!values['offer']) {
    errors['offer'] = 'Обязательный выбор';
  }

  return errors;
};

var _default = (0, _reduxForm.reduxForm)({
  form: 'sign-up-form',
  validate: validate
})(_Component["default"]);

exports["default"] = _default;
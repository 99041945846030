
export default function copyToClipboard(elem) {
  const targetId = "_hiddenCopyText_";
  const isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
  let target, origSelectionStart, origSelectionEnd;

  if (isInput) {
    target = elem;
    origSelectionStart = elem.selectionStart;
    origSelectionEnd = elem.selectionEnd;
  }
  else {
    const target = document.createElement("textarea");
    target.style.position = "absolute";
    target.style.left = "-9999px";
    target.style.top = "0";
    target.id = targetId;
    document.body.appendChild(target);
    target.textContent = elem.innerText;
  }

  const currentFocus = document.activeElement;
  target.focus();
  target.setSelectionRange(0, target.value.length);

  let succeed;
  try {
    succeed = document.execCommand("copy");
  }
  catch(e) {
    succeed = false;
  }

  if (currentFocus && typeof currentFocus.focus === "function") {
    currentFocus.focus();
  }

  if (isInput) {
    elem.setSelectionRange(origSelectionStart, origSelectionEnd);
  }
  else {
    target.textContent = "";
  }

  return succeed;
}

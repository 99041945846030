"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.name = exports.selectInSignUpProcess = exports.selectRedirectTo401 = exports.selectInProcess = exports.selectIsLoaded = exports.selectProfile = exports.selectIsAuth = exports.purchaseRequestSuccessAction = exports.purchaseRequestFailAction = exports.purchaseRequestAction = exports.signOutRequestSuccessAction = exports.signOutRequestFailAction = exports.signOutRequestAction = exports.getProfileRequestSuccessAction = exports.getProfileRequestFailAction = exports.getProfileRequestAction = exports.signUpRequestSuccessAction = exports.signUpRequestFailAction = exports.signUpRequestAction = exports.signInRequestSuccessAction = exports.signInRequestFailAction = exports.signInRequestAction = exports.resetStateAction = exports.isLoadedAction = exports.redirectToAction = void 0;

var _toolkit = require("@reduxjs/toolkit");

var redirectToAction = (0, _toolkit.createAction)('redirect');
exports.redirectToAction = redirectToAction;
var REDUCER_NAME = 'application';
var initialState = {
  categories: [],
  profile: null,
  isAuth: false,
  isLoaded: false,
  inProcess: false,
  inSignUpProcess: false,
  redirectTo401: false
};
var applicationSlice = (0, _toolkit.createSlice)({
  name: REDUCER_NAME,
  initialState: initialState,
  reducers: {
    resetStateAction: function resetStateAction(state) {
      state['items'] = [];
      state['profile'] = null;
      state['isAuth'] = false;
      state['inProcess'] = false;
      state['redirectTo401'] = false;
    },
    isLoadedAction: function isLoadedAction(state) {
      state['isLoaded'] = true;
    },
    getCategoriesRequestAction: function getCategoriesRequestAction(state) {
      state['categories'] = [];
    },
    getCategoriesRequestFailAction: function getCategoriesRequestFailAction(state) {},
    getCategoriesRequestSuccessAction: function getCategoriesRequestSuccessAction(state, _ref) {
      var payload = _ref.payload;
      state['categories'] = payload;
    },
    signInRequestAction: function signInRequestAction(state) {
      state['inProcess'] = true;
    },
    signInRequestFailAction: function signInRequestFailAction(state) {
      state['inProcess'] = false;
    },
    signInRequestSuccessAction: function signInRequestSuccessAction(state, _ref2) {
      var payload = _ref2.payload;
      state['inProcess'] = false;
      state['profile'] = payload;
    },
    signUpRequestAction: function signUpRequestAction(state) {
      state['inSignUpProcess'] = true;
    },
    signUpRequestFailAction: function signUpRequestFailAction(state) {
      state['inSignUpProcess'] = false;
    },
    signUpRequestSuccessAction: function signUpRequestSuccessAction(state) {
      state['inSignUpProcess'] = false;
    },
    getProfileRequestAction: function getProfileRequestAction(state) {
      state['inProcess'] = true;
      state['isAuth'] = false;
    },
    getProfileRequestFailAction: function getProfileRequestFailAction(state) {
      state['inProcess'] = false;
      state['isAuth'] = false;
    },
    getProfileRequestSuccessAction: function getProfileRequestSuccessAction(state, _ref3) {
      var payload = _ref3.payload;
      state['profile'] = payload;
      state['inProcess'] = false;
      state['isAuth'] = true;
    },
    signOutRequestAction: function signOutRequestAction(state) {
      state['inProcess'] = false;
    },
    signOutRequestFailAction: function signOutRequestFailAction(state) {
      state['inProcess'] = false;
    },
    signOutRequestSuccessAction: function signOutRequestSuccessAction(state) {
      state['inProcess'] = false;
    },
    setAvailableMerchants: function setAvailableMerchants(state, _ref4) {
      var payload = _ref4.payload;
      state['merchants'] = payload;
    },
    purchaseRequestAction: function purchaseRequestAction(state) {
      state['purchase'] = null;
      state['inProcess'] = true;
    },
    purchaseRequestFailAction: function purchaseRequestFailAction(state) {
      state['purchase'] = null;
      state['inProcess'] = false;
    },
    purchaseRequestSuccessAction: function purchaseRequestSuccessAction(state, _ref5) {
      var payload = _ref5.payload;
      state['purchase'] = payload;
      state['inProcess'] = false;
    }
  },
  extraReducers: function extraReducers(build) {
    build.addCase(redirectToAction, function (state) {
      state['redirectTo401'] = true;
    });
  }
});
var _applicationSlice$act = applicationSlice['actions'],
    isLoadedAction = _applicationSlice$act.isLoadedAction,
    resetStateAction = _applicationSlice$act.resetStateAction,
    signInRequestAction = _applicationSlice$act.signInRequestAction,
    signInRequestFailAction = _applicationSlice$act.signInRequestFailAction,
    signInRequestSuccessAction = _applicationSlice$act.signInRequestSuccessAction,
    signUpRequestAction = _applicationSlice$act.signUpRequestAction,
    signUpRequestFailAction = _applicationSlice$act.signUpRequestFailAction,
    signUpRequestSuccessAction = _applicationSlice$act.signUpRequestSuccessAction,
    getProfileRequestAction = _applicationSlice$act.getProfileRequestAction,
    getProfileRequestFailAction = _applicationSlice$act.getProfileRequestFailAction,
    getProfileRequestSuccessAction = _applicationSlice$act.getProfileRequestSuccessAction,
    signOutRequestAction = _applicationSlice$act.signOutRequestAction,
    signOutRequestFailAction = _applicationSlice$act.signOutRequestFailAction,
    signOutRequestSuccessAction = _applicationSlice$act.signOutRequestSuccessAction,
    purchaseRequestAction = _applicationSlice$act.purchaseRequestAction,
    purchaseRequestFailAction = _applicationSlice$act.purchaseRequestFailAction,
    purchaseRequestSuccessAction = _applicationSlice$act.purchaseRequestSuccessAction;
exports.purchaseRequestSuccessAction = purchaseRequestSuccessAction;
exports.purchaseRequestFailAction = purchaseRequestFailAction;
exports.purchaseRequestAction = purchaseRequestAction;
exports.signOutRequestSuccessAction = signOutRequestSuccessAction;
exports.signOutRequestFailAction = signOutRequestFailAction;
exports.signOutRequestAction = signOutRequestAction;
exports.getProfileRequestSuccessAction = getProfileRequestSuccessAction;
exports.getProfileRequestFailAction = getProfileRequestFailAction;
exports.getProfileRequestAction = getProfileRequestAction;
exports.signUpRequestSuccessAction = signUpRequestSuccessAction;
exports.signUpRequestFailAction = signUpRequestFailAction;
exports.signUpRequestAction = signUpRequestAction;
exports.signInRequestSuccessAction = signInRequestSuccessAction;
exports.signInRequestFailAction = signInRequestFailAction;
exports.signInRequestAction = signInRequestAction;
exports.resetStateAction = resetStateAction;
exports.isLoadedAction = isLoadedAction;

var selectIsAuth = function selectIsAuth(state) {
  return state[REDUCER_NAME]['isAuth'];
};

exports.selectIsAuth = selectIsAuth;

var selectProfile = function selectProfile(state) {
  return state[REDUCER_NAME]['profile'];
};

exports.selectProfile = selectProfile;

var selectIsLoaded = function selectIsLoaded(state) {
  return state[REDUCER_NAME]['isLoaded'];
};

exports.selectIsLoaded = selectIsLoaded;

var selectInProcess = function selectInProcess(state) {
  return state[REDUCER_NAME]['inProcess'];
};

exports.selectInProcess = selectInProcess;

var selectRedirectTo401 = function selectRedirectTo401(state) {
  return state[REDUCER_NAME]['redirectTo401'];
};

exports.selectRedirectTo401 = selectRedirectTo401;

var selectInSignUpProcess = function selectInSignUpProcess(state) {
  return state[REDUCER_NAME]['inSignUpProcess'];
};

exports.selectInSignUpProcess = selectInSignUpProcess;
var name = applicationSlice['name'];
exports.name = name;
var reducer = applicationSlice['reducer'];
exports.reducer = reducer;

import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './default.module.scss';


function StaticNavigate({ menu }) {
  return (
    <nav className={styles['wrapper']}>
      {menu.map((section, sectionId) => (
        <div key={sectionId} className={styles['section']}>
          {section.map((item, itemId) => (
            <div key={sectionId + '_' + itemId} className={styles['item']}>
              <NavLink className={styles['link']} to={process.env['PUBLIC_URL'] + item['href']}>
                <span className={styles['title']}>{ item['title'] }</span>
              </NavLink>
            </div>
          ))}
        </div>
      ))}
    </nav>
  );
}

export default StaticNavigate;

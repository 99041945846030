"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useRedirect", {
  enumerable: true,
  get: function get() {
    return _useRedirect["default"];
  }
});
Object.defineProperty(exports, "useGetProfile", {
  enumerable: true,
  get: function get() {
    return _useGetProfile["default"];
  }
});

var _useRedirect = _interopRequireDefault(require("./useRedirect"));

var _useGetProfile = _interopRequireDefault(require("./useGetProfile"));
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxForm = require("redux-form");

var _Component = _interopRequireDefault(require("./Component"));

var validate = function validate(values) {
  var errors = {};

  if (!values['paymentMethod']) {
    errors['paymentMethod'] = 'Обязательное поле';
  }

  if (!values['deliveryMethod']) {
    errors['deliveryMethod'] = 'Обязательное поле';
  }

  if (!values['name']) {
    errors['name'] = 'Обязательное поле';
  }

  if (!values['email']) {
    errors['email'] = 'Обязательное поле';
  }

  if (!values['phone']) {
    errors['phone'] = 'Обязательное поле';
  } else if (!/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(values['phone'])) {
    errors['phone'] = 'Неверное значение';
  }

  if (!values['address']) {
    errors['address'] = 'Обязательное поле';
  }

  if (!values['zip']) {
    errors['zip'] = 'Обязательное поле';
  }

  return errors;
};

var _default = (0, _reduxForm.reduxForm)({
  form: 'buy-form',
  validate: validate
})(_Component["default"]);

exports["default"] = _default;

import React from 'react';

import Header from './Header';
import Footer from './Footer';

import styles from './default.module.scss';


export default function NavigateModule({ children }) {
  return (
    <section className={styles['wrapper']}>
      <header className={styles['header']}>
        <Header />
      </header>
      <section className={styles['content']}>
        { children }
      </section>
      <footer className={styles['footer']}>
        <Footer />
      </footer>
    </section>
  );
}


export const http = (value = '') => {
  const regExp = /^(http(s)?):\/\/([^\s]+)\.[^\s]{2,}[^\s]?$/;
  return ! regExp.test(String(value).toLowerCase());
};

export const email = (value = '') => {
  const regExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return regExp.test(String(value).toLowerCase());
};

export const phone = (value = '') => {
  const regExp = /^\+\d{11}/g;
  return regExp.test(String(value).toLowerCase());
};
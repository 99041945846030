
import Application from '@packages/application';
import { reducer as dialogReducer } from '@packages/dialog';
import { notificationReducer } from '@packages/notifications';

import { reducer as formReducer } from 'redux-form';

import routes from './configs/routes';
import navigate from './configs/navigate';

import Empty from './wrappers/Empty';
import Navigate from './wrappers/Navigate';
import Composition from './wrappers/Composition';

import './styles/index.module.scss';


(async function() {
  try {
    const app = new Application({
      routes,
      navigate,
      useSign: true,
      portal: document.getElementById('root'),
      request: {
        host: window.env['API_HOST'],
      },
      reducers: {
        form: formReducer,
        dialog: dialogReducer,
        notifications: notificationReducer,
      },
      wrappers: { Empty, Navigate, Composition },
    });

    app.start();
  }
  catch (error) {

    console.error(error);
  }
})();

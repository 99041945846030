
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import userImg from './user.svg';

function User({ seller }) {
  const location = useLocation();

  function handleCheckPath(event) {
    const currentUrl = location['pathname'];
    const url = `/seller/${seller['uuid']}`;

    currentUrl === url && event.preventDefault();
  }

  return (
    <Link
      className={'controls__user'}
      to={`${process.env['PUBLIC_URL']}/seller/${seller['uuid']}`}
      data-locator={'login_button'}
      onClick={ (event) => handleCheckPath(event) }
    >
      <img src={userImg} alt={'user'} />
    </Link>
  );
}

export default User;

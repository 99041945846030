
import { Logotype } from '@packages/kit';

import React from 'react';

import Controls from './Controls';

import styles from './default.module.scss';


function Header() {
  return (
    <section className={styles['wrapper']}>
      <div className={styles['container']}>
        <div className={styles['logotype']}>
          <Logotype />
        </div>
        <div className={styles['controls']}>
          <Controls />
        </div>
      </div>
    </section>
  );
}

export default Header;


const navigate = [
  {
    "_data": "main",
    "title": "Главная",
    "path": "/",
    "permissions": []
  },
];

export default navigate;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.selectNotifications = exports.cleanNotifications = exports.pushNotification = exports.closeNotification = exports.notificationSlice = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/toConsumableArray"));

var _utils = require("@packages/utils");

var _toolkit = require("@reduxjs/toolkit");

var initialState = {
  items: []
};
var notificationSlice = (0, _toolkit.createSlice)({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    closeNotification: function closeNotification(state, _ref) {
      var payload = _ref.payload;
      state['items'] = state['items'].filter(function (item) {
        return item['uuid'] !== payload;
      });
    },
    pushNotification: function pushNotification(state, _ref2) {
      var payload = _ref2.payload;
      payload['uuid'] = (0, _utils.UUID)();
      state['items'] = [payload].concat((0, _toConsumableArray2["default"])(state['items']));
    },
    cleanNotifications: function cleanNotifications(state) {
      state['items'] = [];
    }
  }
});
exports.notificationSlice = notificationSlice;
var _notificationSlice$ac = notificationSlice['actions'],
    closeNotification = _notificationSlice$ac.closeNotification,
    pushNotification = _notificationSlice$ac.pushNotification,
    cleanNotifications = _notificationSlice$ac.cleanNotifications;
exports.cleanNotifications = cleanNotifications;
exports.pushNotification = pushNotification;
exports.closeNotification = closeNotification;

var selectNotifications = function selectNotifications(state) {
  return state['notifications']['items'];
};

exports.selectNotifications = selectNotifications;
var reducer = notificationSlice['reducer'];
exports.reducer = reducer;

import React from 'react';
import { Link } from 'react-router-dom';

import moderation from './settings.svg';


function Moderation() {
  return (
    <Link className={'control'} to={`${process.env['PUBLIC_URL']}/moderation`} data-locator={'moderation_button'}>
      <img src={moderation} alt={'moderation'} />
    </Link>
  );
}

export default Moderation;

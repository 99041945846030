
const routes = [
  {
    path: '/',
    wrapper: 'Navigate',
    module: import(/* webpackChunkName: "client-products" */'@modules/client-products'),
  },
  {
    path: '/products/:uuid',
    wrapper: 'Navigate',
    module: import(/* webpackChunkName: "client-product" */'@modules/client-product'),
  },
  {
    path: '/users/:uuid',
    wrapper: 'Navigate',
    module: import(/* webpackChunkName: "client-seller" */'@modules/client-seller'),
  },
  {
    path: '/pravila-vozvrata',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-refund" */'@modules/client-refund'),
  },
  {
    path: '/trebovaniya-k-prodavtsam',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-requirements-for-sellers" */'@modules/client-requirements-for-sellers'),
  },
  {
    path: '/trebovaniya-k-tovaram',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-trebovaniya-k-tovaram" */'@modules/client-trebovaniya-k-tovaram'),
  },
  {
    path: '/trebovaniya-k-obrabotke-zakazov',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-trebovaniya-k-obrabotke-zakazov" */'@modules/client-trebovaniya-k-obrabotke-zakazov'),
  },
  {
    path: '/trebovaniya-kplatezham-i-raschetam',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-trebovaniya-kplatezham-i-raschetam" */'@modules/client-trebovaniya-kplatezham-i-raschetam'),
  },
  {
    path: '/trebovaniya-k-reklame',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-trebovaniya-k-reklame" */'@modules/client-trebovaniya-k-reklame'),
  },
  {
    path: '/kak-sdelat-zakaz',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-kak-sdelat-zakaz" */'@modules/client-kak-sdelat-zakaz'),
  },
  {
    path: '/pravila-prodazhi',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-pravila-prodazhi" */'@modules/client-pravila-prodazhi'),
  },
  {
    path: '/oferta',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-oferta" */'@modules/client-oferta'),
  },
  {
    path: '/faq',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-faq" */'@modules/client-faq'),
  },
  {
    path: '/company',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-about-company" */'@modules/client-about-company'),
  },
  {
    path: '/politika-bezopasnosti',
    wrapper: 'Composition',
    module: import(/* webpackChunkName: "client-politika-bezopasnosti" */'@modules/client-politika-bezopasnosti'),
  },
  {
    path: '/moderation',
    wrapper: 'Navigate',
    module: import(/* webpackChunkName: "seller-moderation" */'@modules/seller-moderation'),
  },
  {
    path: '/seller/:uuid',
    wrapper: 'Navigate',
    module: import(/* webpackChunkName: "seller-profile" */'@modules/seller-profile'),
  },
  {
    path: '/history',
    wrapper: 'Navigate',
    module: import(/* webpackChunkName: "seller-history" */'@modules/seller-history'),
  },
];

export default routes;

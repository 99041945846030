
import React from 'react';

import Navigate from '../Navigate';
import StaticNavigate from "./StaticNavigate";

import styles from './default.module.scss';


const staticMenu = [
  [
    {
      title: 'Требования к продавцам',
      href: '/trebovaniya-k-prodavtsam',
    },
  ],[
    {
      title: 'Требования к товару',
      href: '/trebovaniya-k-tovaram',
    },
    {
      title: 'Требования к обработке заказов',
      href: '/trebovaniya-k-obrabotke-zakazov',
    },
    {
      title: 'Требования к учету платежей и расчетам',
      href: '/trebovaniya-kplatezham-i-raschetam',
    },
    {
      title: 'Требования к рекламным материалам',
      href: '/trebovaniya-k-reklame',
    },
  ],
  [
    {
      title: 'Правила возврата товаров',
      href: '/pravila-vozvrata',
    },
    {
      title: 'Политика безопасности',
      href: '/politika-bezopasnosti',
    },
    {
      title: 'Как можно сделать заказ',
      href: '/kak-sdelat-zakaz',
    },
    {
      title: 'Правила продажи товаров через сеть Интернет',
      href: '/pravila-prodazhi',
    },
  ],
  [
    {
      title: 'О компании',
      href: '/company',
    },
    {
      title: 'Оферта на оказание услуг',
      href: '/oferta',
    },
    {
      title: 'Вопросы и ответы',
      href: '/faq',
    },
  ],
];


export default function CompositionModule({ children }) {
  return (
    <Navigate>
      <div className={styles['wrapper']}>
        <div className={styles['navigate']}>
          <StaticNavigate menu={staticMenu} />
        </div>
        <div className={styles['content']}>
          { children }
        </div>
      </div>
    </Navigate>
  );
}

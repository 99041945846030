
export default function getHomePage(navigate, permissions) {
  let item;
  for (item in navigate) {
    if (navigate.hasOwnProperty(item)) {
      const navigateItem = navigate[item];
      if (navigateItem instanceof Object) {
        if ('permissions' in navigateItem) {
          const navigationPermissions = navigateItem['permissions'];
          const hasPermission = navigationPermissions.some((perm) => {
            return (permissions.indexOf(perm) !== -1);
          });
          if (hasPermission) {
            return navigateItem['path'];
          }
        }
      }
    }
  }

  return null;
}

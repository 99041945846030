
import { selectProfile } from '@packages/application';

import { openDialog } from '@packages/dialog';
import { Button, Contacts } from '@packages/kit';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Moderation from './Moderation';
import User from './User';

import styles from './default.module.scss';


function Controls() {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  function handleSignIn() {
    dispatch(openDialog('sign-in'));
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['section']}>
        <Contacts underline={true} />
      </div>
      { ! profile && (
        <div className={styles['section']}>
          <Button
            _data={'sign-in'}
            type={'button'}
            onClick={() => handleSignIn()}
          >Начать продавать</Button>
        </div>
      )}
      {(profile && profile['seller']) && (
        <div className={styles['section']}>
          <User seller={profile['seller']} />
        </div>
      )}
      {(profile && profile['seller'] && profile['roles'].indexOf('Moderator') !== -1) && (
        <div className={styles['section']}>
          <Moderation />
        </div>
      )}
    </div>
  );
}

export default Controls;

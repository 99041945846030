"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = initStore;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/toConsumableArray"));

var _toolkit = require("@reduxjs/toolkit");

function initStore() {
  var reducers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var middleware = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return (0, _toolkit.configureStore)({
    reducer: (0, _toolkit.combineReducers)(reducers),
    middleware: (0, _toConsumableArray2["default"])(middleware),
    devTools: process.env['NODE_ENV'] === 'development'
  });
}
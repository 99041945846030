
export * as validation from './validation';
export { default as getHomePage } from './getHomePage';
export { queryToObject, objectToQuery } from './query';
export { default as copyToClipboard } from './copyToClipboard';


export const UUID = () => {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt/16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};

export function getObjectByKey(object, key, predicate) {
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
    return object;
  }

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]];
    if (typeof value === "object" && value != null) {
      let o = getObjectByKey(object[Object.keys(object)[i]], key, predicate)
      if (o !== null) {
        return o;
      }
    }
  }
  return null;
}

export const formatter = (num) => {
  let parts = num.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};
